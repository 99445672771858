import {
  type ClientLoaderFunctionArgs,
  Outlet,
  type ShouldRevalidateFunctionArgs,
  useLoaderData,
} from '@remix-run/react';
import { $path } from 'remix-routes';

import { type AdminPublicLibraryData } from '../../app/components/PublicLibrary/AdminPublicLibraryLayout';
import { useUserAnalytics } from '../analytics/user';
import { AdminAccess } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import { type BreadcrumbNode } from '../components/Breadcrumbs';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import {
  GameCenterContextProvider,
  HomeBreadcrumb,
} from '../components/Game/GameCenter';
import { PageManagerUtils } from '../components/PageManager/utils';
import { PortalLink } from '../components/PortalContext';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useInstance } from '../hooks/useInstance';
import { AdminView } from '../pages/Admin/AdminView';
import { GamePackContextProvider } from '../pages/GamePack/Context';
import { GamePackCRUDUtility } from '../pages/GamePack/CRUDUtility';
import { apiService } from '../services/api-service';

export async function clientLoader(
  action: ClientLoaderFunctionArgs
): Promise<AdminPublicLibraryData> {
  const { name } = action.params;
  if (!PageManagerUtils.IsPageName(name)) {
    throw new Error('Invalid page name');
  }
  const page = (await apiService.page.get(name)).data.page;
  return {
    pageName: name,
    page,
  };
}

export function shouldRevalidate(args: ShouldRevalidateFunctionArgs) {
  const currentPageName = args.currentParams.name;
  const nextPageName = args.nextParams.name;
  if (currentPageName === nextPageName) {
    return false;
  }
  return args.defaultShouldRevalidate;
}

export function Component() {
  const { pageName } = useLoaderData<typeof clientLoader>();

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  const routePrefix = $path('/admin/public-library/:name', {
    name: pageName,
  });

  const breadcrumb = useInstance<BreadcrumbNode>(() => ({
    key: 'GamePacks',
    node: <HomeBreadcrumb label={'Public Library'} to={routePrefix} />,
  }));

  return (
    <ProvidersList providers={providers}>
      <AdminView
        search={{
          targetPathname: 'search',
          placeholder: 'Search Public Library',
        }}
        notificationCenter
      >
        <GameCenterContextProvider>
          <GamePackContextProvider
            breadcrumb={breadcrumb}
            routePrefix={routePrefix}
            editRoutePrefix={PortalLink.AdminGamePack}
            embed={false}
            mode='admin'
            pageType={'public'}
          >
            <Outlet />

            <GamePackCRUDUtility />
          </GamePackContextProvider>
        </GameCenterContextProvider>
      </AdminView>

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
